import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { resetPasswordService } from '../service';

export const useResetPassword = () => {
  const mutation = useMutation(({ password, token }: { password: string; token: string }) =>
    resetPasswordService.resetPassword({ password, token }),
  );

  const handleResetPassword = async (password: string, token: string, onSuccess?: () => void) => {
    mutation.mutate(
      { password, token },
      {
        onSuccess: () => {
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err);
        },
      },
    );
  };

  return { handleResetPassword, isLoading: mutation.isLoading, mutation };
};
