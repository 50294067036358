export const colors = [
  '#07BEB8',
  '#EFD6AC',
  '#DAD641',
  '#219ebc',
  '#fefae0',
  '#9a8c98',
  '#c8b6ff',
  '#cbf3f0',
  '#eb5e28',
  '#f5ebe0',
  '#98c1d9',
];

export const chartColors = [
  '#587570',
  '#EFD6AC',
  '#AF6B48',
  '#219ebc',
  '#fefae0',
  '#9a8c98',
  '#c8b6ff',
  '#cbf3f0',
  '#eb5e28',
  '#f5ebe0',
  '#98c1d9',
];

export const getChartColor = (idx: number) => {
  const len = chartColors.length;
  if (len === 0) return '#fff';

  const modIdx = idx % (2 * len);

  const adjustedIdx = modIdx < len ? modIdx : 2 * len - modIdx - 1;

  return chartColors[adjustedIdx];
};
