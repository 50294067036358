import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, InputLabel, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { KQNBaseInput, LoadingOverlay } from '../../../components';
import { KoqoonLogo } from '../../../shared';
import { userService } from '../../user';
import { RegisterSchema, RegisterType } from '../schemas';

const RegisterPage = () => {
  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
    const redirectIfNeeded = () => {
      if (process.env.REACT_APP_ENV === 'production') {
        window.location.href = `${process.env.REACT_APP_SHOPIFY_STORE_LOGIN_URL}account/register`;
        return;
      }

      setIsRedirecting(false);
    };

    redirectIfNeeded();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<RegisterType>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
  });

  const handleOnSubmit = async (data: RegisterType) => {
    try {
      const response = await userService.register(data);

      if (response.message) {
        toast.success(response.message);
        reset();
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  if (isRedirecting) return <LoadingOverlay />;

  return (
    <Box sx={{ height: '100vh', maxWidth: 'sm', alignItems: 'center', mx: 'auto' }}>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{
          py: 2,
          width: '100%',
          height: '48px',
          boxShadow: '0px 8px 20px 0px rgba(66, 68, 90, 0.05)',
        }}
      >
        <KoqoonLogo width='165px' height='30px' />
      </Stack>
      <Stack
        sx={{
          px: 2,
          mt: 12,
          gap: 2,
        }}
      >
        <Typography variant='h5'>Don&apos;t have an account yet?</Typography>
        <Typography variant='body1'>Create Account</Typography>
        <Stack
          sx={{
            height: '100%',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <form style={{ width: '100%' }} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack sx={{ width: '100%', gap: 3 }}>
              <FormControl variant='standard'>
                <InputLabel shrink htmlFor='firstName'>
                  First Name *
                </InputLabel>
                <KQNBaseInput
                  id='firstName'
                  {...register('firstName')}
                  name='firstName'
                  fullWidth
                  placeholder='First Name'
                  error={!!errors.firstName}
                />
                {errors.firstName && (
                  <Typography variant='caption' color='error'>
                    {errors.firstName?.message}
                  </Typography>
                )}
              </FormControl>
              <FormControl variant='standard'>
                <InputLabel shrink htmlFor='lastName'>
                  Last Name *
                </InputLabel>
                <KQNBaseInput
                  id='lastName'
                  {...register('lastName')}
                  name='lastName'
                  fullWidth
                  placeholder='Last Name'
                />
                {errors.lastName && (
                  <Typography variant='caption' color='error'>
                    {errors.lastName?.message}
                  </Typography>
                )}
              </FormControl>
              <FormControl variant='standard'>
                <InputLabel shrink htmlFor='email'>
                  Email *
                </InputLabel>
                <KQNBaseInput
                  id='email'
                  {...register('email')}
                  name='email'
                  fullWidth
                  type='email'
                  placeholder='Email'
                  autoComplete='username'
                />
                {errors.email && (
                  <Typography variant='caption' color='error'>
                    {errors.email?.message}
                  </Typography>
                )}
              </FormControl>
              <FormControl variant='standard'>
                <InputLabel shrink htmlFor='password'>
                  Password *
                </InputLabel>
                <KQNBaseInput
                  autoComplete='current-password'
                  id='password'
                  {...register('password')}
                  name='password'
                  fullWidth
                  type='password'
                  placeholder='Password'
                />
                {errors.password && (
                  <Typography variant='caption' color='error'>
                    {errors.password?.message}
                  </Typography>
                )}
              </FormControl>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ my: 2, borderRadius: '8px !important' }}
              >
                Create Account
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Box>
  );
};

export default RegisterPage;
