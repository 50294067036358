import { z } from 'zod';
import { PASSWORD_REGEX } from '../../../constants';

export const RegisterSchema = z.object({
  firstName: z.string().min(3, '').max(25),
  lastName: z.string().min(3, '').max(50),
  email: z.string().min(1, { message: 'Must be a valid email address.' }).email({
    message: 'Must be a valid email',
  }),
  password: z
    .string()
    .min(
      8,
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    )
    .regex(PASSWORD_REGEX),
});

export type RegisterType = z.infer<typeof RegisterSchema>;
