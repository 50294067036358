import { Box, Stack } from '@mui/material';
import React from 'react';
import { KoqoonFooter } from '../../../components';
import { KoqoonLogo } from '../../../shared';

type Props = {
  children: React.ReactNode;
  language?: string;
};

const PagesWrapper: React.FC<Props> = ({ language, children }) => {
  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        overflowX: 'hidden',
        minHeight: '100vh',
        background: '#1F1D1D',
        color: '#fff',
        overflow: 'hidden',
      }}
    >
      <Stack
        direction='column'
        alignItems='center'
        height='100%'
        sx={{ width: '100%', gap: 5, overflow: 'auto' }}
      >
        <KoqoonLogo
          style={{ width: '150px', height: '40px', fill: '#AF6B48', paddingTop: '22px' }}
        />
        <Stack
          direction='column'
          height='100%'
          alignItems='center'
          gap={10}
          justifyContent='space-between'
          maxWidth='700px'
        >
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              width: '100%',
            }}
          >
            {children}
          </Box>
          <KoqoonFooter language={language} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PagesWrapper;
