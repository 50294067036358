import { Box, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Paths } from '../../../../constants';
import { useTranslations } from '../../../../shared';
import { ResetPasswordSchemaType } from '../../schemas';
import { ResetPasswordForm, useResetPassword } from './reset-password-form';

interface Props {
  language?: string;
}

const ResetPasswordPage: React.FC<Props> = ({ language = 'en' }) => {
  const [params] = useSearchParams();
  const token = params.get('token');
  const translations = useTranslations({ language });
  const { handleResetPassword, isLoading, mutation } = useResetPassword();
  const navigate = useNavigate();

  const handleOnSubmit = async (data: ResetPasswordSchemaType) => {
    if (!token) {
      toast.error('Please provide the token.');
      return;
    }

    const { password, confirmPassword } = data;

    if (!password || !confirmPassword) {
      toast.error('Password and confirm password are required');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    await handleResetPassword(password, token, () => {
      navigate(Paths.RESET_PASSWORD_SUCCESS, { replace: true });
    });
  };

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 'sm',
        }}
      >
        <Typography variant='h6' fontWeight='bold'>
          {translations.passwordResetTitle}
        </Typography>
        <Stack direction='column' gap={2}>
          <Typography variant='h6'>{translations.passwordResetText}</Typography>
          <ul style={{ textAlign: 'left', paddingInlineStart: '15px' }}>
            <li>{translations.firstPasswordRule}</li>
            <li>{translations.secondPasswordRule}</li>
            <li>{translations.thirdPasswordRule}</li>
            <li>{translations.fourthPasswordRule}</li>
          </ul>
        </Stack>
      </Box>
      <ResetPasswordForm
        token={token}
        language={language}
        onSubmit={handleOnSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default ResetPasswordPage;
