import { koqoonApi } from '../../../config';
import { CustomerAccountSchemaType } from '../schemas';

const authPath = '/auth';

async function forgotPassword(email: string) {
  const path = `${authPath}/forgot-password`;
  const body = { email };

  try {
    const response = await koqoonApi.post(path, body);

    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function resetPassword(password: string, token: string) {
  const path = `${authPath}/reset-password`;
  const body = {
    password,
    token,
  };

  try {
    const response = await koqoonApi.post(path, body);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function activateCustomerAccount(args: CustomerAccountSchemaType) {
  try {
    const response = await koqoonApi.post('/users/customer-account-activation', args);

    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

export const authService = {
  resetPassword,
  forgotPassword,
  activateCustomerAccount,
};
