import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { KQNTextInputField } from '../../../../../components';
import { useTranslations } from '../../../../../shared';
import { resetPasswordSchema, ResetPasswordSchemaType } from '../../../schemas';

type Props = {
  onSubmit: (data: ResetPasswordSchemaType) => Promise<void>;
  isLoading?: boolean;
  token?: string | null;
  language?: string;
};

const ResetPasswordForm: React.FC<Props> = ({ onSubmit, isLoading, language }) => {
  const translations = useTranslations({ language });

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} width='100%'>
      <Stack direction='column' alignItems='center' gap={2}>
        <KQNTextInputField
          fullWidth
          type='password'
          name='password'
          control={control}
          label={translations?.newPasswordLabelText || 'New Password'}
          borderColor='white'
          borderRadius='5px'
          InputProps={{ sx: { color: 'white' } }}
          required
          height='52px'
        />
        <KQNTextInputField
          type='password'
          name='confirmPassword'
          control={control}
          borderRadius='5px'
          label={translations?.confirmPasswordLabelText || 'Confirm Password'}
          required
          height='52px'
          borderColor='white'
          InputProps={{ sx: { color: 'white' } }}
        />
      </Stack>
      <Stack justifyContent='flex-end' alignContent='center' direction='row' gap={2} mt={2}>
        <Button
          type='submit'
          variant='contained'
          sx={{ textTransform: 'uppercase' }}
          startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
        >
          {translations?.passwordResetButton || 'submit'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ResetPasswordForm;
