import { Skeleton, Stack } from '@mui/material';

const PieChartSkeleton = () => {
  return (
    <Stack
      gap={2}
      sx={{
        p: 2,
        width: '300px',
        height: '350px',
        borderRadius: '8px',
        border: '1px solid #B9B9B9',
      }}
      justifyContent='center'
      alignItems='center'
    >
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <Skeleton variant='rounded' width={150} height={30} />
      </Stack>
      <Skeleton variant='circular' width={200} height={200} />
      <Stack
        direction='row'
        sx={{ alignItems: 'center' }}
        gap={2}
        alignItems='center'
        justifyContent='center'
      >
        <Skeleton variant='rounded' width={50} height={30} />
        <Skeleton variant='rounded' width={50} height={30} />
        <Skeleton variant='rounded' width={50} height={30} />
      </Stack>
    </Stack>
  );
};

export default PieChartSkeleton;
