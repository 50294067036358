import { Stack, Typography } from '@mui/material';
import { useTranslations } from '../../../shared';

interface Props {
  language?: string;
}

const ActivateAccountPage: React.FC<Props> = ({ language = 'en' }) => {
  const translations = useTranslations({ language });

  return (
    <>
      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
        {translations.accountActivation}
      </Typography>
      <Stack direction='column' gap={1}>
        <Typography variant='h6'>{translations.accountActivatedTitle}</Typography>
        <Typography variant='body1'>{translations.accountActivated}</Typography>
        <Typography variant='body1'>{translations.accountActivatedSubText}</Typography>
      </Stack>
    </>
  );
};

export default ActivateAccountPage;
