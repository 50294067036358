import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { authService } from '../services';

interface CustomerAccountSchemaType {
  firstName: string;
  lastName: string;
  password: string;
  token: string;
}

export const useActivateCustomerAccount = () => {
  const mutation = useMutation((args: CustomerAccountSchemaType) =>
    authService.activateCustomerAccount(args),
  );

  const handleAccountActivation = async (data: CustomerAccountSchemaType) => {
    mutation.mutate(data, {
      onSuccess: () => {
        toast.success('Customer account successfully activated');
      },
      onError: (err: any) => {
        toast.error(err);
      },
    });
  };

  return { mutation, handleAccountActivation, isLoading: mutation.isLoading };
};
