import { Route, Routes } from 'react-router-dom';
import { Paths } from '../../constants';
import { AccessoriesOutlet } from '../accessories';
import { ForgotPasswordPage, LoginPage, TokenVerificationPage } from '../authentication';
import { CategoriesOutlet } from '../categories';
import { DashboardOutlet, DashboardPage } from '../dashboard';
import { IngredientsOutlet } from '../ingredients';
import { RecipeOutlet, RecipesPage } from '../recipes';
import { UnitOutlet } from '../units';
import {
  AccessoriesDetails,
  AccessoriesPage,
  AnalyticsPage,
  CategoriesDetails,
  CategoriesPage,
  CountryPieChartGraph,
  CustomerAccountActivationPage,
  GrillErrorsPage,
  GuidedModePage,
  IngredientsDetails,
  IngredientsPage,
  MobileUsersIndex,
  MobileUsersPage,
  OsPieChartGraphPage,
  RecipesCreatePage,
  RegisterPage,
  ResetSuccessfulPage,
  SettingsPage,
  UnitDetails,
  UnitsPage,
  UsersPage,
} from './lazy-import';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={Paths.LOGIN} element={<LoginPage />} />
      <Route path={Paths.TOKEN_VERIFICATION} element={<TokenVerificationPage />} />
      <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={Paths.REGISTER} element={<RegisterPage />} />
      <Route path={Paths.RESET_PASSWORD_SUCCESS} element={<ResetSuccessfulPage />} />
      <Route path={Paths.CUSTOMER_ACTIVATION} element={<CustomerAccountActivationPage />} />
      <Route path={Paths.BASE} element={<DashboardOutlet />}>
        <Route path={Paths.ADMIN_USERS} element={<UsersPage />} />
        <Route path={Paths.SETTINGS} element={<SettingsPage />} />

        <Route path='' element={<DashboardPage />} />
        <Route path='recipes' element={<RecipeOutlet />}>
          <Route path='accessories' element={<AccessoriesOutlet />}>
            <Route path='' element={<AccessoriesPage />} />
            <Route path=':id' element={<AccessoriesDetails />} />
          </Route>
          <Route path='units' element={<UnitOutlet />}>
            <Route path='' element={<UnitsPage />} />
            <Route path=':id' element={<UnitDetails />} />
          </Route>
          <Route path='ingredients' element={<IngredientsOutlet />}>
            <Route path='' element={<IngredientsPage />} />
            <Route path=':id' element={<IngredientsDetails />} />
          </Route>
          <Route path='categories' element={<CategoriesOutlet />}>
            <Route path='' element={<CategoriesPage />} />
            <Route path=':id' element={<CategoriesDetails />} />
          </Route>
          <Route path='overview' element={<RecipesPage />} />
          <Route path='details' element={<RecipesCreatePage />} />
          <Route path='create' element={<RecipesCreatePage />} />
        </Route>
        <Route path={Paths.ANALYTICS} element={<AnalyticsPage />}>
          <Route path='reports' />
          <Route path='mobile-users' element={<MobileUsersPage />}>
            <Route path='' element={<MobileUsersIndex />} />
            <Route path='operation-system' element={<OsPieChartGraphPage />} />
            <Route path='countries' element={<CountryPieChartGraph />} />
          </Route>
          <Route path='grill-errors' element={<GrillErrorsPage />} />
          <Route path='guide-grilling' element={<GuidedModePage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
