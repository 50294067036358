import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress, FormControl, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { KQNBaseInput } from '../../../components';
import { NavigationPaths } from '../../../constants';
import { MainWrapper } from '../../../shared';
import { useForgotPassword } from '../mutations';
import { forgotPasswordSchema, ForgotPasswordSchemaType } from '../schemas';

const ForgotPasswordPage: React.FC = () => {
  const { handleForgotPassword, isLoading } = useForgotPassword();
  const navigate = useNavigate();

  const { handleSubmit, register } = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const handleOnSubmit = async (data: ForgotPasswordSchemaType) => {
    if (!data.email) return;
    await handleForgotPassword({
      email: data.email,
      onSuccess: () => navigate(NavigationPaths.LOGIN),
    });
  };

  return (
    <MainWrapper component='main' maxWidth='xs'>
      <Box sx={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }} />
      <Typography component='h1' variant='h5'>
        Forgot Password
      </Typography>
      <Box component='form' onSubmit={handleSubmit(handleOnSubmit)} sx={{ mt: 1 }}>
        <FormControl variant='standard' fullWidth>
          <KQNBaseInput
            id='email'
            {...register('email')}
            name='email'
            fullWidth
            type='email'
            placeholder='Email'
          />
        </FormControl>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ my: 2, borderRadius: '8px !important' }}
          startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
        >
          Submit
        </Button>
      </Box>
    </MainWrapper>
  );
};

export default ForgotPasswordPage;
