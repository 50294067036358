import { z } from 'zod';
import { PASSWORD_REGEX } from '../../../constants';

export const customerAccountSchema = z.object({
  firstName: z.string().min(2, 'First name cannot be empty.'),
  lastName: z.string().min(2, 'Last name cannot be empty'),
  password: z.string().min(1, 'Password cannot be empty').regex(PASSWORD_REGEX, {
    message:
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
  }),
});

export type CustomerAccountSchemaType = z.infer<typeof customerAccountSchema>;
