/* eslint-disable react/no-array-index-key */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, Typography } from '@mui/material';
import { Cell, Pie, PieChart } from 'recharts';
import ChartValues from '../../modules/analytics/components/chart-values';
import PieChartSkeleton from '../../modules/analytics/components/pie-chart-skeleton';
import { PieChartType } from '../../modules/analytics/types';
import { getChartColor } from '../../shared';

interface Props {
  title: string;
  values?: PieChartType[];
  onClick?: () => void;
  isLoading?: boolean;
}

export const PieChartReport: React.FC<Props> = ({ title, values, onClick, isLoading }) => {
  if (isLoading) return <PieChartSkeleton />;

  return (
    <Stack
      gap={2}
      sx={{
        p: 2,
        width: '300px',
        minHeight: '350px',
        borderRadius: '8px',
        border: '1px solid #B9B9B9',
        overflowX: 'hidden',
      }}
      justifyContent='center'
      alignItems='center'
    >
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <Typography sx={{ fontWeight: 600, fontSize: 22 }}>{title}</Typography>
        {!!onClick && (
          <ArrowForwardIosIcon onClick={onClick} sx={{ ':hover': { curso: 'pointer' } }} />
        )}
      </Stack>
      <PieChart width={300} height={200}>
        <Pie
          data={values}
          dataKey='value'
          nameKey='name'
          cx='50%'
          cy='50%'
          animationDuration={300}
          outerRadius={90}
          innerRadius={60}
        >
          {values?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getChartColor(index)} />
          ))}
        </Pie>
      </PieChart>
      <ChartValues values={values} />
    </Stack>
  );
};
