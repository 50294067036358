import { koqoonApi } from '../../../../../../config';

export const resetPassword = async ({ password, token }: { password: string; token: string }) => {
  try {
    const response = await koqoonApi.post('/auth/reset-password', { password, token });

    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const resetPasswordService = {
  resetPassword,
};
