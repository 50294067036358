/* eslint-disable react/no-unused-prop-types */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, Chip, Paper, Stack, Typography } from '@mui/material';
import { InfiniteData } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '../../constants';
import { RecipeTooltip } from '../../modules/ingredients';
import { RecipeBaseModel } from '../../modules/types';
import { defaultImageUrl } from '../../shared';
import { PaginatedResult } from '../../types';

type Props = {
  recipes: InfiniteData<PaginatedResult<RecipeBaseModel>>;
  ref?: React.Ref<HTMLDivElement>;
};

const AssociatedRecipes = React.forwardRef<HTMLDivElement, Props>(({ recipes }, ref) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (id: string) => {
      navigate(`${NavigationPaths.DETAILS}?recipeId=${id}`);
    },
    [navigate],
  );

  return (
    <Paper sx={{ minWidth: '500px', p: 3, overflow: 'auto' }}>
      <Stack direction='column' gap={3} width='100%'>
        <Typography>ASSOCIATED RECIPES</Typography>

        {recipes?.pages.map((page) =>
          page.data.map((recipe, idx) => (
            <Stack
              key={recipe.id}
              direction='row'
              justifyContent='flex-start'
              gap={2}
              alignItems='center'
            >
              <RecipeTooltip
                displayValue={
                  <OpenInNewOutlinedIcon
                    className='icon'
                    sx={{ color: 'kqn.copper', cursor: 'pointer' }}
                    onClick={() => handleNavigate(recipe.id)}
                  />
                }
              >
                <Box ref={idx === page.data.length - 1 ? ref : null} key={recipe.id}>
                  <Stack gap={2} justifyContent='space-between' direction='row'>
                    <Stack
                      direction='row'
                      width='100%'
                      gap={2}
                      sx={{
                        bgcolor: 'kqn.white',
                        p: 1,
                        pr: 2,
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.4)',
                        borderRadius: '8px',
                        '&:hover': { cursor: 'pointer' },
                      }}
                      onClick={() => handleNavigate(recipe.id)}
                    >
                      <img
                        src={recipe.thumbnailUrl || recipe.imageUrl || defaultImageUrl}
                        alt={recipe.name}
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                      <Stack direction='column' justifyContent='space-between' width='100%'>
                        <Typography variant='body2'>{recipe.name}</Typography>
                        <Stack
                          direction='row'
                          alignItems='center'
                          justifyContent='space-between'
                          gap={2}
                          sx={{ color: 'kqn.darkGray' }}
                        >
                          <Stack direction='row' alignItems='center' gap={0.5}>
                            <AccessTimeIcon />
                            <Typography variant='body2'>{recipe.cookingTime}min</Typography>
                          </Stack>
                          <Typography variant='body2' align='right'>
                            {recipe.complexity.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='flex-start'
                      gap={2}
                      sx={{ flexBasis: '30%' }}
                    >
                      {recipe.categories?.map((category) => {
                        return (
                          <Chip
                            key={category.id}
                            label={category.name}
                            sx={{
                              color: 'kqn.darkerGray',
                              borderRadius: '8px',
                              bgcolor: 'kqn.lightGray',
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Box>
              </RecipeTooltip>
              <Typography sx={{ fontWeight: 600 }}>{recipe.name}</Typography>
            </Stack>
          )),
        )}
      </Stack>
    </Paper>
  );
});

export default AssociatedRecipes;
