import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { getChartColor } from '../../../shared';
import { PieChartType } from '../types';

type Props = {
  values?: PieChartType[];
};

const ChartValues: React.FC<Props> = ({ values }) => {
  return (
    <Stack direction='row' gap={1} alignItems='center' justifyContent='center' flexWrap='wrap'>
      {values?.map((v, idx) => {
        return (
          <Stack
            key={v.id}
            sx={{ border: '1px solid #eee', borderRadius: '8px', px: 1.5 }}
            direction='row'
            alignItems='center'
            gap='6px'
          >
            <CircleIcon sx={{ color: getChartColor(idx), width: '8px' }} />
            <Stack justifyContent='center' alignItems='center'>
              <Typography variant='caption' sx={{ textTransform: 'capitalize' }}>
                {v.name}
              </Typography>
              <Typography variant='caption'>{v.value}</Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ChartValues;
