import { z } from 'zod';
import { PASSWORD_REGEX } from '../../../constants';

export const resetPasswordSchema = z.object({
  password: z
    .string()
    .min(
      8,
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    )
    .regex(PASSWORD_REGEX),
  confirmPassword: z
    .string()
    .min(
      8,
      'Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    )
    .regex(PASSWORD_REGEX),
});

export type ResetPasswordSchemaType = z.infer<typeof resetPasswordSchema>;
